import { remoteData } from "@devexperts/remote-data-ts";
import { fromNullable, getOrElse, isSome, none, some } from "fp-ts/lib/Option";

import { NcaishCorporateOnboardingApiService } from "../api/ncaishCorporateOnboardingApi.service";

//import { AccountHolder } from "../model/accountHolder";

import { CorporateServiceInfo } from "../model/corporateServiceInfo";
import { CorporateUser } from "../model/corporateUser";
import { CorpReviewApiResponse } from "../model/corpReviewApiResponse";
import { CorpServicesApiResponse } from "../model/corpServicesApiResponse";
import { CorpUpdateServiceApiResponse } from "../model/corpUpdateServiceApiResponse";
import { GetContractReceipientApiResponse } from "../model/getContractReceipientApiResponse";

import { SendBrResponse } from "../model/sendBrResponse";

import { UpdateSignatoriesApiResponse } from "../model/updateSignatoriesApiResponse";

//import { TAccountHolder } from "../types/account-holder.type";
import { TApiBrInfo } from "../types/api-br-info";
import { TBaseResponse, TOtpResponse } from "../types/base-response.type";
import { TBrReceipient } from "../types/br-receipient.type";
import { TContractReceipient } from "../types/contract-receipient.type";
import { TCorporateInfo } from "../types/corporate-info.type";
import { TCorporateReviewResponse } from "../types/corporate-review-response.type";
import { TCorporateService } from "../types/corporate-service.type";
import { TCorporateUser } from "../types/corporate-user.type";

import { TReceipientDto } from "../types/update-receipient-request.type";

import { TValidateCorpApiResponse } from "../types/validate-corp-api-response.type";
import { TCorpServicesApiResponse } from "../types/corp-services-api-response.type";
import { TCorpUpdateServiceApiResponse } from "../types/corp-update-service-api-response.type";
import { BrReceipientInfo } from "../model/brReceipientInfo";
import { unWrapOption } from "../utils/option.utils";
import { ConvertToRoutePath } from "../utils/route.utils";
import { ValidateApplicationApiResponse } from "../model/validateApplicationApiResponse";
import { CorpApplicationApiResponse } from "../model/corpApplicationApiResponse";
import { CorporateApplicationApiInfo } from "../model/corporateApplicationApiInfo";
import { ApiBrInfo } from "../model/apiBrInfo";
import { AxiosRequestHeaders } from "axios";
import { store } from "../state/store";
import { UpdateServiceApiRequest } from "../model/updateServiceApiRequest";
import { CreateCorporateOnboardingRequest } from "../model/createCorporateOnboardingRequest";
import { CreateCorporateOnboardingResponse } from "../model/createCorporateOnboardingResponse";

// eslint-disable-next-line @typescript-eslint/no-namespace

export namespace OnboardingService {
  export const validateCompanyCin = async (cin: string) => {
    const result = await NcaishCorporateOnboardingApiService.validateCin({
      cin,
    });
    return remoteData.map(result, parseCompanyCin);
  };

  export const getContractReceipient = async (draftId: string) => {
    const appToken = store.getState().token.appToken;
    const result =
      await NcaishCorporateOnboardingApiService.getContractReceipient({
        "app-token": unWrapOption(appToken, () => ""),
      });
    return remoteData.map(result, parseContractReceipient);
  };

  export const getReview = async (customHeaders: AxiosRequestHeaders = {}) => {
    const appToken = store.getState().token.appToken;
    const result = await NcaishCorporateOnboardingApiService.myApplication({
      "app-token": unWrapOption(appToken, () => ""),
    });
    return remoteData.map(result, parseReview);
  };

  export const updateSignatories = async (
    receipients: Array<TReceipientDto>,
    draftId: string
  ) => {
    const appToken = store.getState().token.appToken;
    const result = await NcaishCorporateOnboardingApiService.updateSignatories(
      {
        data: receipients.map((r) => ({
          type: r.type,
          info: {
            userId: r.userId,
            name: r.name,
            email: r.email,
            mobile: r.mobile,
          },
        })),
      },
      {
        "app-token": unWrapOption(appToken, () => ""),
      }
    );

    return remoteData.map(result, (br: UpdateSignatoriesApiResponse) => ({
      successful: fromNullable(br.successful),
      message: fromNullable(br.message),
      applicationId: fromNullable(br.applicationId),
      nextAction: fromNullable(ConvertToRoutePath(br.nextAction)),
    }));
  };

  export const createCorporateOnboarding = async (
    body: CreateCorporateOnboardingRequest
  ) => {
    const result =
      await NcaishCorporateOnboardingApiService.createCorporateOnboarding(body);

    return remoteData.map(
      result,
      (response: CreateCorporateOnboardingResponse) => response
    );
  };

  export const getServices = async () => {
    const appToken = store.getState().token.appToken;
    const result = await NcaishCorporateOnboardingApiService.services1({
      "app-token": unWrapOption(appToken, () => ""),
    });
    return remoteData.map(result, parseCorpServices);
  };

  export const updateServices = async (
    services: Array<string>,
    draftId: string
  ) => {
    const appToken = store.getState().token.appToken;
    const result = await NcaishCorporateOnboardingApiService.services(
      {
        data: services,
      },
      {
        "app-token": unWrapOption(appToken, () => ""),
      }
    );
    return remoteData.map(result, parseServicesResponse);
  };

  export const sendBrApplication = async (accountId: string) => {
    const appToken = store.getState().token.appToken;
    const result = await NcaishCorporateOnboardingApiService.sendBr({
      "app-token": unWrapOption(appToken, () => ""),
    });
    return remoteData.map(result, (br: SendBrResponse) => ({
      successful: fromNullable(br.successful),
      message: fromNullable(br.message),
      nextAction: fromNullable(ConvertToRoutePath(br.nextAction)),
      //draftId: fromNullable(br.draftId),
    }));
  };

  const parseServicesResponse = (
    response: CorpUpdateServiceApiResponse
  ): TCorpUpdateServiceApiResponse => {
    return {
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      nextScreen: fromNullable(ConvertToRoutePath(response.nextAction)),
      draftId: fromNullable(response.draftId),
    };
  };

  const parseCorpServices = (
    corp: CorpServicesApiResponse
  ): TCorpServicesApiResponse => {
    return {
      services: corp.services ? corp.services.map(parseService) : [],
      successful: fromNullable(corp.successful),
      message: fromNullable(corp.message),
    };
  };

  const parseContractReceipient = (
    response: GetContractReceipientApiResponse
  ): TContractReceipient => {
    return {
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      receipients: response.receipients
        ? response.receipients.map(parseReceipients)
        : [],
      directors: response.directors
        ? response.directors.map(parseDirectors)
        : [],
    };
  };

  const parseReview = (
    response: CorpApplicationApiResponse
  ): TCorporateReviewResponse => {
    const data = fromNullable(response.data);
    const corporateInfo = isSome(data)
      ? some(parseCorporateInfo(data.value))
      : none;
    return {
      data: corporateInfo,
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      nextAction: none, //fromNullable(ConvertToRoutePath(response.nextAction)),
    };
  };

  const parseCompanyCin = (
    response: ValidateApplicationApiResponse
  ): TValidateCorpApiResponse => {
    return {
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      nextAction: fromNullable(ConvertToRoutePath(response.nextAction)),
      applicationId: fromNullable(response.applicationId),
    };
  };

  // const parseAccountHolder = (acc?: AccountHolder): TAccountHolder => {
  //   return acc
  //     ? {
  //         name: fromNullable(acc.name),
  //         email: fromNullable(acc.email),
  //         mobile: fromNullable(acc.mobile),
  //         emailVerified: fromNullable(acc.emailVerified),
  //         mobileVerified: fromNullable(acc.mobileVerified),
  //       }
  //     : {
  //         name: none,
  //         email: none,
  //         mobile: none,
  //         emailVerified: none,
  //         mobileVerified: none,
  //       };
  // };
  const parseDirectors = (user: CorporateUser): TCorporateUser => {
    return {
      userId: fromNullable(user.userId),
      name: fromNullable(user.name),
      email: fromNullable(user.email),
      mobile: fromNullable(user.mobile),
    };
  };
  const parseReceipients = (receipient: BrReceipientInfo): TBrReceipient => {
    return {
      type: fromNullable(receipient.type),
      status: fromNullable(receipient.status),
      name: fromNullable(receipient.info?.name),
      email: fromNullable(receipient.info?.email),
      mobile: fromNullable(receipient.info?.mobile),
      userId: fromNullable(receipient.info?.userId),
    };
  };

  const parseService = (service: CorporateServiceInfo): TCorporateService => {
    return {
      type: fromNullable(service.type),
      name: fromNullable(service.name),
      img: fromNullable(service.img),
      description: fromNullable(service.description),
      selected: fromNullable(service.selected),
    };
  };

  const parseBrInfo = (acc?: ApiBrInfo): TApiBrInfo => {
    return acc
      ? {
          status: fromNullable(acc.status),
          document: fromNullable(acc.document),
          sentOn: fromNullable(acc.sentOn),
          receipients: acc.receipients
            ? acc.receipients.map(parseReceipients)
            : [],
        }
      : {
          status: none,
          document: none,
          sentOn: none,
          receipients: [],
        };
  };

  const parseCorporateInfo = (
    corp: CorporateApplicationApiInfo
  ): TCorporateInfo => {
    //const accountHolder = fromNullable(corp.accountHolder);
    //const directors = fromNullable(corp.directors);
    const brInfo = parseBrInfo(corp.brInfo);
    const directors: TCorporateUser[] = brInfo.receipients
      .filter((rec) => unWrapOption(rec.type, () => "") === "DIRECTOR")
      .map((d) => ({
        userId: d.userId,
        name: d.name,
        email: d.email,
        mobile: d.mobile,
      }));

    return {
      applicationId: fromNullable(corp.applicationId),
      name: fromNullable(corp.name),
      cin: fromNullable(corp.cin),
      state: fromNullable(corp.state),
      roc: fromNullable(corp.roc),
      doi: fromNullable(corp.doi),
      regNo: fromNullable(corp.regNo),
      address: fromNullable(corp.address),
      //accountHolder: parseAccountHolder(corp.accountHolder),
      directors: corp.directors ? corp.directors.map(parseDirectors) : [],
      brInfo: brInfo,
      services: corp.services ? corp.services.map(parseService) : [],
    };
  };
}
